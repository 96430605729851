let currentlyPlaying = null;
let currentlyPlayingButton = null;
export function playSong(btn) {
  var media = btn.querySelector("audio");
  if (currentlyPlaying && currentlyPlaying != media) {
    currentlyPlaying.pause();
    currentlyPlayingButton.classList.toggle("js-paused");
  }
  if (currentlyPlaying && currentlyPlaying == media) {
    currentlyPlaying.pause();
    currentlyPlayingButton.classList.toggle("js-paused");
    currentlyPlaying = null;
    currentlyPlayingButton = null;
  } else {
    currentlyPlaying = media;
    currentlyPlayingButton = btn;
    currentlyPlaying.volume = 0.3;
    currentlyPlaying.play();
    currentlyPlayingButton.classList.toggle("js-paused");
  }
}
